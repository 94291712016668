<template>
  <el-dialog title="领取记录"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="70%">
    <table-page dataName="receiveList"
                ref="table"
                init
                @on-success="fetchSuccess"
                :params="paramsObj"
                :request="fetchRewardRecords">
      <template slot="button">
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="exportUrl"
                   @click="exportData">导出领取记录</el-button>
      </template>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="card_number"
                       show-overflow-tooltip
                       label="身份证号">
      </el-table-column>
      <el-table-column prop="openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>
      <el-table-column prop="gift_id"
                       show-overflow-tooltip
                       label="礼品ID">
      </el-table-column>
      <el-table-column prop="org_id"
                       show-overflow-tooltip
                       label="网点ID">
      </el-table-column>
      <el-table-column prop="gift_money"
                       show-overflow-tooltip
                       label="领取金额">
      </el-table-column>
      <el-table-column prop="status"
                       show-overflow-tooltip
                       label="领取状态">
      </el-table-column>
      <el-table-column prop="received_at"
                       show-overflow-tooltip
                       label="领取时间">
      </el-table-column>
    </table-page>
    <span slot="footer"
          class="dialog-footer">

    </span>
  </el-dialog>
</template>

<script>
import TablePage from '@/components/TablePage'
import { fetchRewardRecords } from '@/api/market'
export default {
  props: ['params'],
  data () {
    return {
      fetchRewardRecords,
      dialogVisible: false,
      paramsObj: {
      },
      exportUrl: ''
    }
  },
  components: {
    TablePage
  },

  methods: {
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    showTable (id) {
      this.dialogVisible = true
      this.paramsObj = {
        activity_id: id
      }
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    },
    exportData () {
      window.open(this.exportUrl, '_blank')
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
